import { graphql } from 'gatsby';
import React from 'react';
import { NotFoundPageQuery } from '../graphql-generated';
import Page from '../templates/page';
import { PageProps } from '../types/pageProps';

const NotFoundPage: React.FC<PageProps<NotFoundPageQuery>> = ({ data }) => {
  const page = data.wp?.options?.acfOptions?.errorPages?.notfound;
  if (page?.__typename !== 'WpPage') return null;

  return (
    <Page
      data={{
        page,
      }}
    />
  );
};

export const query = graphql`
  query NotFoundPageQuery {
    wp {
      options {
        acfOptions {
          errorPages {
            notfound {
              ... on WpPage {
                __typename
                title
                template {
                  templateName
                }
                contentAST {
                  ...ContentAST
                }
                date
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80) {
                          presentationWidth
                          presentationHeight
                          ...TechImageSharpFluid_withWebp
                        }
                      }
                    }
                    caption
                    altText
                    title
                  }
                }

                acfPageLayout {
                  sidebar {
                    content {
                      ...PageSidebarContent
                    }
                  }
                  mainSection {
                    content {
                      ...PageMainSectionContent
                    }
                  }
                  bottomSection {
                    content {
                      ...PageBottomSectionContent
                    }
                  }
                  lowestSection {
                    content {
                      ...PageLowestSectionContent
                    }
                  }
                  latestNews {
                    showLatestNewsAtTheTop
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
